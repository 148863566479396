import { Controller } from "stimulus"

export default class extends Controller {
  static values = { config: Object }

  connect() {
    this.chart = new window.Chart(this.element.getContext("2d"), this.config)
  }

  disconnect() {
    this.chart?.destroy()
  }

  configValuechanged() {
    this.chart.data.labels = this.configValue.labels
    this.chart.data.datasets = this.configValue.datasets
    this.chart.update()
  }

  get config() {
    return {
      type: this.configValue.type,
      data: {
        labels: this.configValue.labels,
        datasets: this.configValue.datasets
      },
      options: {
        ...this.options,
        maintainAspectRatio: false,
        responsive: true,
        hover: { mode: null },
        animation: { animateScale: false, animateRotate: false },
        plugins: this.plugins
      }
    }
  }

  get options() {
    if (this.configValue.type == "doughnut") {
      return {
        interaction: {
          mode: "dataset"
        }
      }
    }

    return {
      indexAxis: 'y',
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            stepSize: 1
          }
        },
        y: {
          stacked: true
        }
      },
      interaction: {
        mode: "index"
      }
    }
  }

  get plugins() {
    if (this.configValue.type == "doughnut") {
      return {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          callbacks: {
            label(context) {
              if (context.raw.tooltipLabel === false) {
                return null
              }
              return context.raw.tooltipLabel
            }
          }
        }
      }
    }

    return {
      legend: {
        position: 'bottom',
      }
    }
  }
}
