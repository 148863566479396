import { Controller } from "stimulus"

const formatNumber = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2 }).format

const formatPercentage = (value, suffix = "%") => {
  const formatted = formatNumber(value) + suffix
  return formatted.trim()
}

const initialChartOptions = {
  cutout: "85%",
  responsive: true,
  maintainAspectRatio: false,
  legend: false,
  tooltips: { enabled: false },
  hover: { mode: null },
  animation: { animateScale: false, animateRotate: true }
}

export default class extends Controller {
  static targets = [ "chart", "count", "diff", "previousResult" ]
  static values = {
    type: { type: String, default: "bench" },
    denominator: { type: Object },
    unit: String
  }

  get config() {
    return {
      type: "doughnut",
      data: { datasets: this.datasets },
      options: {
        ...initialChartOptions,
        plugins: {
          centerText: {
            display: true,
            text: this.centerTextLabel,
            color: "#757575"
          },
          tooltip: {
            filter(context) {
              return context.raw.tooltip !== false
            }
          }
        }
      }
    }
  }

  get datasets() {
    return [
      {
        data: [
          { value: this.denominatorValue[this.typeValue] - this.getTypeValue("current"), tooltip: false },
          { value: this.getTypeValue("current") }
        ],
        backgroundColor: [ '#e6e6e6', '#b5d294' ]
      }
    ]
  }

  get centerTextLabel() {
    return formatPercentage(this.getTypeValue("current"), this.unitValue)
  }

  initialize() {
    this.chart = new window.Chart(this.chartTarget.getContext("2d"), this.config)
  }

  disconnect() {
    this.chart?.destroy()
  }

  typeValueChanged() {
    this.updateChart()
  }

  updateChart() {
    this.chart.data.datasets = this.datasets
    this.chart.config.options.plugins.centerText.text = this.centerTextLabel
    this.chart.update()

    this.countTarget.innerHTML = this.data.get("count")
    this.previousResultTarget.innerHTML = formatPercentage(this.getTypeValue("previous"), this.unitValue)

    const diff = this.getTypeValue("diff")
    this.diffTarget.innerHTML = diff + this.unitValue
    this.diffTarget.className = "site-chart-diff-" + { "-": "down", "+": "up" }[diff[0]]
  }

  getTypeValue(key) {
    return JSON.parse(this.data.get(key))[this.typeValue]
  }
}
