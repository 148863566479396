import { Controller } from "stimulus"
import { showNode, hideNode } from "../utils"

export default class extends Controller {
  static targets = [ "control", "item" ]

  connect() {
    this.#show(this.controlTarget.value)
  }

  toggle(event) {
    this.#show(event.target.value)
  }

  #show(item) {
    this.itemTargets.forEach(el => {
      if (el.dataset.elementToggleItem == item) {
        el.querySelectorAll("input, select, textarea").forEach(field => { field.disabled = false })
        showNode(el)
      } else {
        el.querySelectorAll("input, select, textarea").forEach(field => { field.disabled = true })
        hideNode(el)
      }
    })
  }
}
