import { Controller } from "stimulus"
import { squish } from "../utils"

export default class extends Controller {
  static targets = [ "field" ]
  static values = { confirmation: String }
  static classes = [ "failure", "invalidField" ]

  connect() {
    this.element.addEventListener("submit", this.validateConfirmation)
  }

  disconnect() {
    this.element.removeEventListener("submit", this.validateConfirmation)
  }

  validateConfirmation = e => {
    if (this.allFieldsValid()) { return }

    e.preventDefault()
    this.element.addEventListener(
      "animationend",
      () => { this.element.classList.remove(this.failureClass) },
      { once: true }
    )
    this.element.classList.add(this.failureClass)
  }

  allFieldsValid() {
    return this.fieldTargets.every(el => {
      if (squish(el.value).toLowerCase() == squish(this.confirmationValue).toLowerCase()) {
        return true
      }

      el.classList.add(this.invalidFieldClass)
      return false
    })
  }
}
