import { Controller } from "stimulus"

export default class extends Controller {
  static values = { events: Array }
  static targets = [ "remove" ]

  connect() {
    this.eventsValue.forEach(event => {
      this.element.addEventListener(event, this[`${event}Handler`])
    })
  }

  disconnect() {
    this.eventsValue.forEach(event => {
      this.element.removeEventListener(event, this[`${event}Handler`])
    })
  }

  changeHandler = () => {
    this.removeTargets.forEach(el => el.remove())
  }

  removeClass(e) {
    e.currentTarget.classList.remove(e.params.class)
  }
}
