/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import { defineCustomElements } from "ionicons/dist/esm/loader"

/* eslint-disable camelcase */
defineCustomElements(window, { resourcesUrl: `${__webpack_public_path__}ionicons/` })
/* eslint-enable camelcase */

import "whatwg-fetch"
import "core-js/stable/dom-collections/for-each"
// polyfills for the two users of old (v60) users of firefox
import "@ungap/custom-elements"
import "../polyfills/resize-observer"
import { Turbo } from "@hotwired/turbo-rails"
import "../turbo-stream-actions"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import "trix"

import "../dropdowns"
import "../modals"
import "../tabs"

import "../channels"

window.Turbolinks = { visit: Turbo.visit }

import * as ActiveStorage from "@rails/activestorage"

ActiveStorage.start()

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

function requireAll(r) { r.keys().forEach(r) }
requireAll(require.context('behaviors', true, /\.js$/))

import { Autocomplete } from 'stimulus-autocomplete'

application.register('autocomplete', Autocomplete)

import TextareaAutogrow from 'stimulus-textarea-autogrow'

application.register('textarea-autogrow', TextareaAutogrow)
