import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modal", "disableField", "destroyTemplate", "destroyMessage" ]

  disconnect() {
    document.documentElement.classList.remove("is-clipped")
  }

  show(e) {
    e.stopPropagation()

    this.modalTarget.classList.add("is-active")
    document.documentElement.classList.add("is-clipped")
  }

  hide(e) {
    e.stopPropagation()

    this.modalTarget.classList.remove("is-active")
    document.documentElement.classList.remove("is-clipped")
  }

  destroy(e) {
    e.preventDefault()
    if (this.data.get("persisted") == "true") {
      this.destroyPersisted()
    } else {
      this.destroyTransient()
    }
  }

  destroyPersisted() {
    this.disableFields(true)
    this.element.style.position = "relative"
    this.element.appendChild(this.destroyTemplateTarget.content.cloneNode(true))
  }

  destroyTransient() {
    this.element.parentNode.removeChild(this.element)
  }

  disableFields(state) {
    if (this.hasDisableFieldTarget) {
      this.disableFieldTargets.forEach(el => { el.disabled = state })
    }
  }

  restore(e) {
    e.preventDefault()
    this.destroyMessageTarget.remove()
    this.disableFields(false)
  }
}
