import Mustache from "mustache"

const ohop = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop)
const { getComputedStyle } = window

const expando = "__quatoo"
const expandoKey = n => `${expando}_${n}`

const styleDisplayKey = expandoKey("style.display")

const clearNode = node => {
  while (node.firstChild) {
    node.removeChild(node.firstChild)
  }
}

const hideNode = node => {
  if (getComputedStyle(node).display == "none") { return }
  if (node.style.display != "") {
    node[styleDisplayKey] = node.style.display
  }
  node.style.display = "none"
}

const showNode = node => {
  if (getComputedStyle(node).display != "none") { return }

  let value = ""
  if (node.style.display == "" && getComputedStyle(node).display == "none") {
    value = "block"
  }
  if (ohop(node, styleDisplayKey) && node[styleDisplayKey] != "") {
    value = node[styleDisplayKey]
  }
  node.style.display = value
}

const isVisible = el => getComputedStyle(el).display != "none"

const isEmptyNode = node => node.childNodes.length == 0

const renderTemplate = (tpl, data) => Mustache.render(tpl, data)

const xhrHeaders = options => {
  options = options || {}
  options.headers = options.headers || {}
  options.headers['X-Requested-With'] = 'XMLHttpRequest'
  options.credentials = options.credentials || "same-origin"
  return options
}

const xhr = (url, options) => {
  return fetch(url, xhrHeaders(options))
}

// https://stackoverflow.com/a/4825695/683728
const setCookie = (name, value, days) => {
  let expires
  let date
  if (days) {
    date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = `; expires=${date.toGMTString()}`
  } else {
    expires = ""
  }
  const cookieLine = `${name}=${value}${expires}; path=/`
  document.cookie = cookieLine
}

const deleteCookie = name => setCookie(name, null, -1)

const arrayMoveMutate = (array, from, to) => {
  const startIndex = to < 0 ? array.length + to : to
  const item = array.splice(from, 1)[0]
  array.splice(startIndex, 0, item)
}

const arrayMove = (array, from, to) => {
  array = array.slice()
  arrayMoveMutate(array, from, to)
  return array
}

const fragment = content => {
  const frag = document.createDocumentFragment()
  const temp = document.createElement('div')
  temp.innerHTML = content
  while (temp.firstChild) { frag.appendChild(temp.firstChild) }
  return frag
}

const sanitizeIdentifier = value => {
  return value.replace(/\]\[|[^-a-zA-Z0-9:.]/g, "_")
}

// https://gist.github.com/6174/6062387
const randomString = () => {
  const rand1 = Math.random().toString(36).substring(2, 15)
  const rand2 = Math.random().toString(36).substring(2, 15)

  return rand1 + rand2
}

// https://github.com/afcapel/stimulus-autocomplete/blob/537644c1ed378b5c201f1f74a61497292ce2db6c/src/autocomplete.js#L291
const debounce = (fn, delay = 350) => {
  let timeoutId = null

  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => { fn.apply(this, args) }, delay)
  }
}

function createElement(tag, attrs) {
  const el = document.createElement(tag)
  Object.entries(attrs || {}).forEach(([ k, v ]) => {
    if (k in el) {
      el[k] = v
    } else {
      el.setAttribute(k, v)
    }
  })

  return el
}

function once(el, ev, fn) {
  el.addEventListener(ev, fn, { once: true })
}

function squish(str) {
  if (!str) { return "" }

  return `${str}`.replace(/\s+/g, " ").trim()
}

export { clearNode, isEmptyNode, renderTemplate, showNode, hideNode, isVisible,
  xhr, setCookie, deleteCookie, arrayMove, fragment, sanitizeIdentifier,
  randomString, debounce, ohop, createElement, once, squish }
